<template>
  <validation-observer
    ref="diplomaStudentSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="diplomaStudentSaveModal"
      centered
      no-close-on-backdrop
      body-class="position-static"
      size="lg"
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="status">
          <template v-slot:label>
            Kết quả xét duyệt <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Kết quả xét duyệt"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statuses"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="note">
          <template v-slot:label>
            Nội dung <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Nội dung"
            rules="required"
          >
            <b-form-textarea
              id="note"
              v-model="targetData.note"
              name="note"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('diplomaStudentSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'

export const Attr = Object.freeze({
  store: 'diplomaStudent',
})
export default {
  name: 'DiplomaStudentSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BFormTextarea,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        diplomaId: null,
        studentId: null,
        note: '',
        status: null,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      maxOrderNo: `${Attr.store}/maxOrderNo`,
      resourceName: `${Attr.store}/resourceName`,
      courseSemesters: 'dropdown/courseSemesters',
    }),
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getMaxOrderNo: `${Attr.store}/getMaxOrderNo`,
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = {
          id: this.mainData.id,
          diplomaId: this.mainData.diplomaId,
          studentId: this.mainData.studentId,
          note: this.mainData.note,
          status: this.mainData.status,
        }
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .diplomaStudentSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        diplomaId: null,
        studentId: null,
        note: '',
        status: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .diplomaStudentSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('diplomaStudentSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
